<script>
import DataTableCustomFilter from '@/components/DataTableCustomFilter'

export default {
  name: 'DataTableTransportationDocCustomFilter',
  extends: DataTableCustomFilter,
  data() {
    return {
      nameFilter: this.$i18n.t('invoices'),
      store: 'invoicesStore',
      objectKey: 'documentCode',
    }
  },
  methods: {
    prepareData(data) {
      return data.padStart('9', '0')
    },
  },
}
</script>
