<template>
  <div>
    <v-btn outlined color="#6A6E7B" block class="btn--width40 mt-n7" @click.stop="openDialog()">
      <v-badge color="red" overlap bottom :value="count" :content="count">
        <v-icon> mdi-filter </v-icon>
      </v-badge>
    </v-btn>

    <v-dialog v-model="dialog" persistent :max-width="$vuetify.breakpoint.xsOnly ? '100vw' : '50vw'">
      <v-card>
        <v-card-title>
          <span class="headline">{{ nameFilter }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-0">
          <v-autocomplete
            v-model="dataList"
            :items="itemsData"
            @paste.prevent="pasteData($event)"
            @click:clear="clearFilter()"
            outlined
            clearable
            multiple
            chips
            deletable-chips
            small-chips
          />
        </v-card-text>

        <v-card-actions class="pb-6 px-8">
          <v-spacer />
          <div class="red--text mr-12" v-if="filterNotApplied">{{ $t('filterNotApplied') }}</div>
          <v-btn color="primary" @click="applyFilter(true)">
            <v-icon left>mdi-filter</v-icon>
            {{ $t('apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'DataTableCustomFilter',
  props: {
    data: {
      type: [Array, Object],
      default: null,
    },
  },
  data() {
    return {
      nameFilter: 'Filtro',
      objectKey: '',
      dialog: false,
      dataList: [],
      filters: {},
      count: 0,
      store: '',
    }
  },
  created() {
    this.$root.$on('clear-input-filters', () => {
      this.clearFilter()
    })
  },
  methods: {
    ...mapActions({
      setFilter(dispatch, payload) {
        return dispatch(`${this.store}/setFilter`, payload)
      },
    }),
    clearFilter() {
      this.dataList = []
      this.filters = {}
      this.filters[this.objectKey] = []
      this.count = 0
      this.setFilter(this.filters)
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      if (this.filterNotApplied) {
        return
      }
      this.dialog = false
    },
    applyFilter(close) {
      this.filters = { ...this.filters, ...this.getFilter }
      this.filters[this.objectKey] = this.dataList
      this.count = this.dataList.length
      this.setFilter(this.filters)
      if (close) {
        this.closeDialog()
      }
    },
    prepareData(data) {
      return data
    },
    pasteData(evt) {
      let pasteData = evt.clipboardData.getData('text').split(/\r?\n/g)
      pasteData.forEach((data) => {
        let preparedData = this.prepareData(data)
        if (this.itemsData.includes(preparedData) && this.dataList.indexOf(preparedData) < 0) {
          this.dataList.push(preparedData)
        }
      })
    },
  },
  computed: {
    filterNotApplied() {
      return this.count > this.dataList.length
    },
    itemsData() {
      return this.data
        .map((el) => el[this.objectKey])
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((el) => el != null)
        .sort()
    },
    ...mapState({
      getFilter(_, getters) {
        return getters[`${this.store}/getFilter`]
      },
    }),
  },
}
</script>

<style scoped>
.btn--width40 {
  width: 40px;
}
</style>
